#embedded-player {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; ///was 100%
    width: 100vw; ///was 100%
    background-color: #001f32;
    // }
    .times {
        position: absolute;
        z-index: 999;
        img {
            width: 100%;
            filter: invert(1);
        }
    }
    .embedded-player {
        position: relative;
        iframe {
            width: 100%;
            height: 100%;
        }
        .player-text {
            display: none;
        }
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .embedded-container-dekstop {
        display: none;
    }
    #embedded-player > div.embedded-container-mobile > div {
        width: 85vw;
        height: 30vh;
        bottom: 5rem;
    }
    .times {
        width: 5vw;
        top: 15%;
        right: 0.9rem;
        opacity: 1;
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
    .embedded-container-mobile {
        display: none;
    }
    .embedded-player {
        width: 50vw;
        height: 55vh;
        iframe {
            transform: scale(1.1);
        }
    }
    .times {
        width: 2vw;
        top: 5%;
        right: 4%;
        opacity: 1;
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    .embedded-container-dekstop {
        display: none;
    }
    #embedded-player > div.embedded-container-mobile > div {
        width: 85vw;
        height: 35vh;
        bottom: 5rem;
    }
    .times {
        width: 4vw;
        top: 7%;
        right: 1.4rem;
        opacity: 1;
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .embedded-container-mobile {
        display: none;
    }
    .embedded-player {
        width: 65vw;
        height: 55vh;
        iframe {
            transform: scale(1.1);
        }
    }
    .times {
        width: 2vw;
        top: 5%;
        right: 4%;
        opacity: 1;
    }
}
///Desktop
@media screen and (min-width: 1280px) {
    .embedded-container-mobile {
        display: none;
    }
    .embedded-player {
        width: 55vw;
        height: 60vh;
        margin-top: 4%;
        iframe {
            transform: scale(1.2);
        }
    }
    .times {
        width: 1.5vw;
        top: 5%;
        right: 4%;
        opacity: 0.8;
        cursor: pointer;
        &:hover {
            opacity: 1;
            transform: rotate(180deg);
            transition: 300ms linear;
        }
    }
}
