.topsection-awards-container {
    position: relative;
    width: 100vw;
    text-transform: uppercase;
    // background-color: black;
    font-family: "Roboto Condensed", sans-serif;
}
///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .topsection-awards-container {
        height: 150vh;
    }
    .awards-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        // justify-content: center;
        // width: 100%;
        left: 50%;
        top: 9rem;
        transform: translate(-50%);
    }
    ///AWARDS 2021 H1 & H3
    .award-container-desktop-2021 {
        flex-direction: column;
        width: 100%;
        // margin: 0px 0px 5px 35px;
        // padding: 0px 0px 5px 35px;
        line-height: 1.5rem;
        h1 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 16px;
            // margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 13.5px;
            font-weight: 100;
            opacity: 0.9;
            // margin-bottom: 0px;
            line-height: 0.7rem;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
    ///AWARDS 2020 H1 & H3
    .award-container-desktop-2020 {
        flex-direction: column;
        width: 100%;
        // margin: 0px 35px 5px 35px;
        // padding: 0px 35px 5px 35px;
        line-height: 1.5rem;
        h1 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 16px;
            // margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 13.5px;
            font-weight: 100;
            opacity: 0.9;
            // margin-bottom: 0px;
            line-height: 0.7rem;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
    .topsection-awards-container {
        height: 200vh;
    }
    .awards-container {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        // width: 100%;
        left: 50%;
        top: 6rem;
        transform: translate(-50%);
    }
    ///AWARDS 2021 H1 & H3
    .award-container-desktop-2021 {
        width: 40%;
        margin: 15px 0px 0px 5px;
        line-height: 0.7rem;
        h1 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 16px;
        }
        h3 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 13.5px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 0.7rem;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
    ///AWARDS 2020 H1 & H3
    .award-container-desktop-2020 {
        width: 40%;
        margin: 15px 0px 0px 5px;
        line-height: 0.7rem;
        h1 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 16px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 13.5px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 0.7rem;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    .topsection-awards-container {
        height: 100vh;
    }
    .awards-container {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90%;
        left: 50%;
        top: 13rem;
        transform: translate(-50%);
    }
    ///AWARDS 2021 H1 & H3
    .award-container-desktop-2021 {
        width: 40%;
        // margin: 15px 5px 15px 50px;
        // padding: 15px 5px 15px 50px;
        margin: 15px 0px 10px 35px;
        padding: 15px 0px 10px 35px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 19px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 15px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 11px;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
    ///AWARDS 2020 H1 & H3
    .award-container-desktop-2020 {
        width: 40%;
        // margin: 15px 50px 15px 5px;
        // padding: 15px 50px 15px 5px;
        margin: 15px 0px 10px 35px;
        padding: 15px 35px 10px 0px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 19px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 15px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 11px;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .topsection-awards-container {
        height: 100vh;
    }
    .awards-container {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        left: 50%;
        top: 11rem;
        transform: translate(-50%);
    }
    ///AWARDS 2021 H1 & H3
    .award-container-desktop-2021 {
        // width: 30%;
        margin: 15px 5px 15px 50px; // margin: 15px 20px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 19px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 15px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 11px;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
    ///AWARDS 2020 H1 & H3
    .award-container-desktop-2020 {
        // width: 30%;
        margin: 15px 50px 15px 5px; // margin: 15px 20px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 19px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 15px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 11px;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    .topsection-awards-container {
        height: 100vh;
    }
    .awards-container {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        left: 50%;
        top: 11rem;
        transform: translate(-50%);
    }
    ///AWARDS 2021 H1 & H3
    .award-container-desktop-2021 {
        // width: 35%;
        margin: 15px 0px 15px 50px; // margin: 15px 20px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 19px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 15px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 11px;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
    ///AWARDS 2020 H1 & H3
    .award-container-desktop-2020 {
        // width: 35%;
        margin: 15px 0px 15px 50px; // margin: 15px 20px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 19px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 15px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 11px;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    .topsection-awards-container {
        height: 100vh;
    }
    .awards-container {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        left: 50%;
        top: 12rem;
        transform: translate(-50%);
    }
    ///AWARDS 2021 H1 & H3
    .award-container-desktop-2021 {
        // width: 35%;
        // margin: 15px 0px 15px 20px;
        margin: 15px 0px 15px 20px; // margin: 15px 20px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 20px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 16px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 12px;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
    ///AWARDS 2020 H1 & H3
    .award-container-desktop-2020 {
        // width: 35%;
        margin: 15px 0px 0px 95px; // margin: 15px 20px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 20px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 16px;
            font-weight: 100;
            opacity: 0.9;
            margin-bottom: 0px;
            line-height: 12px;
            i {
                font-size: 10px;
                color: #f5a1a3;
            }
        }
    }
}
