#fiction-container {
    position: relative;
    background-color: black;
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    #grid-wrapper-fiction {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(7, 90vh); ///eventueel 100vh
        // grid-auto-rows: 1fr;
        gap: 5px;
    }
    .one-f {
        grid-column: 1/2;
        grid-row: 1/2;
    }
    .two-f {
        grid-column: 1/2;
        grid-row: 2/3;
    }
    .three-f {
        grid-column: 1/2;
        grid-row: 3/4;
    }
    .four-f {
        grid-column: 1/2;
        grid-row: 4/5;
    }
    .five-f {
        grid-column: 1/2;
        grid-row: 5/6;
    }
    .six-f {
        grid-column: 1/2;
        grid-row: 6/7;
    }
    .seven-f {
        grid-column: 1/2;
        grid-row: 7/8;
    }
    //CARD ONE
    #grid-wrapper-fiction div.one-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.one-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.one-f > a div {
        background-size: auto 100%;
        background-position: 40%;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper-fiction div.two-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.two-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.two-f > a div {
        background-size: auto 105%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper-fiction div.three-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.three-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.three-f > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper-fiction div.four-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.four-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.four-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper-fiction div.five-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.five-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.five-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper-fiction div.six-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.six-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.six-f > a div {
        background-size: auto 100%;
        background-position: 80%;
        background-repeat: no-repeat;
    }
    //CARD SEVEN
    #grid-wrapper-fiction div.seven-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.seven-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.seven-f > a div {
        background-size: auto 150%;
        background-position: 25%;
        background-repeat: no-repeat;
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
    #grid-wrapper-fiction {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 200px);
        gap: 5px;
    }
    ///2 BIG
    .one-f {
        grid-column: 1/4;
        grid-row: 1/3;
    }
    .two-f {
        grid-column: 4/7;
        grid-row: 1/3;
    }
    //3 SMALL
    .three-f {
        grid-column: 1/3;
        grid-row: 3/5;
    }
    .four-f {
        grid-column: 3/5;
        grid-row: 3/5;
    }
    .five-f {
        grid-column: 5/7;
        grid-row: 3/5;
    }
    //2 BIG
    .six-f {
        grid-column: 1/4;
        grid-row: 5/7;
    }
    .seven-f {
        grid-column: 4/7;
        grid-row: 5/7;
    }
    //CARD SEVEN
    #grid-wrapper-fiction div.seven-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.seven-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.seven-f > a div {
        background-size: auto 150%;
        background-position: 30%;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper-fiction div.six-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.six-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.six-f > a div {
        background-size: auto 100%;
        background-position: 65%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper-fiction div.five-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.five-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.five-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper-fiction div.four-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.four-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.four-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper-fiction div.three-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.three-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.three-f > a div {
        background-size: auto 100%;
        background-position: 50% bottom;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper-fiction div.two-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.two-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.two-f > a div {
        background-size: auto 110%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD ONE
    #grid-wrapper-fiction div.one-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.one-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.one-f > a div {
        background-size: auto 100%;
        background-position: 10%;
        background-repeat: no-repeat;
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    #grid-wrapper-fiction {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(9, 200px);
        gap: 5px;
    }
    ///2 BIG
    .one-f {
        grid-column: 1/4;
        grid-row: 1/4;
    }
    .two-f {
        grid-column: 4/7;
        grid-row: 1/4;
    }
    //3 SMALL
    .three-f {
        grid-column: 1/3;
        grid-row: 4/7;
    }
    .four-f {
        grid-column: 3/5;
        grid-row: 4/7;
    }
    .five-f {
        grid-column: 5/7;
        grid-row: 4/7;
    }
    //2 BIG
    .six-f {
        grid-column: 1/4;
        grid-row: 7/10;
    }
    .seven-f {
        grid-column: 4/7;
        grid-row: 7/10;
    }
    //CARD SEVEN
    #grid-wrapper-fiction div.seven-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.seven-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.seven-f > a div {
        background-size: auto 150%;
        background-position: 25%;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper-fiction div.six-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.six-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.six-f > a div {
        background-size: auto 100%;
        background-position: 65%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper-fiction div.five-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.five-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.five-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper-fiction div.four-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.four-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.four-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper-fiction div.three-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.three-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.three-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper-fiction div.two-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.two-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.two-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD ONE
    #grid-wrapper-fiction div.one-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.one-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.one-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    #grid-wrapper-fiction {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(9, 200px);
        gap: 5px;
    }
    ///2 BIG
    .one-f {
        grid-column: 1/4;
        grid-row: 1/4;
    }
    .two-f {
        grid-column: 4/7;
        grid-row: 1/4;
    }
    //3 SMALL
    .three-f {
        grid-column: 1/3;
        grid-row: 4/7;
    }
    .four-f {
        grid-column: 3/5;
        grid-row: 4/7;
    }
    .five-f {
        grid-column: 5/7;
        grid-row: 4/7;
    }
    //2 BIG
    .six-f {
        grid-column: 1/4;
        grid-row: 7/10;
    }
    .seven-f {
        grid-column: 4/7;
        grid-row: 7/10;
    }
    //CARD SEVEN
    #grid-wrapper-fiction div.seven-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.seven-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.seven-f > a div {
        background-size: auto 150%;
        background-position: 30%;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper-fiction div.six-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.six-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.six-f > a div {
        background-size: auto 100%;
        background-position: 65%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper-fiction div.five-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.five-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.five-f > a div {
        background-size: auto 100%;
        background-position: 55%;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper-fiction div.four-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.four-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.four-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper-fiction div.three-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.three-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.three-f > a div {
        background-size: auto 100%;
        background-position: 50% bottom;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper-fiction div.two-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.two-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.two-f > a div {
        background-size: auto 110%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD ONE
    #grid-wrapper-fiction div.one-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.one-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.one-f > a div {
        background-size: auto 100%;
        background-position: 25%;
        background-repeat: no-repeat;
    }
}
///Desktop
@media screen and (min-width: 1280px) {
    #grid-wrapper-fiction {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(9, 200px);
        gap: 5px;
    }
    ///2 BIG
    .one-f {
        grid-column: 1/4;
        grid-row: 1/4;
    }
    .two-f {
        grid-column: 4/7;
        grid-row: 1/4;
    }
    //3 SMALL
    .three-f {
        grid-column: 1/3;
        grid-row: 4/7;
    }
    .four-f {
        grid-column: 3/5;
        grid-row: 4/7;
    }
    .five-f {
        grid-column: 5/7;
        grid-row: 4/7;
    }
    //2 BIG
    .six-f {
        grid-column: 1/4;
        grid-row: 7/10;
    }
    .seven-f {
        grid-column: 4/7;
        grid-row: 7/10;
    }
    //CARD SEVEN
    #grid-wrapper-fiction div.seven-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.seven-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.seven-f > a div {
        background-size: auto 150%;
        background-position: 35%;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper-fiction div.six-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.six-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.six-f > a div {
        background-size: auto 100%;
        background-position: 70%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper-fiction div.five-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.five-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.five-f > a div {
        background-size: auto 100%;
        background-position: 40% 30%;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper-fiction div.four-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.four-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.four-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper-fiction div.three-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.three-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.three-f > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper-fiction div.two-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.two-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.two-f > a div {
        background-size: auto 110%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD ONE
    #grid-wrapper-fiction div.one-f a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper-fiction > div.one-f > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper-fiction > div.one-f > a div {
        background-size: auto 100%;
        background-position: 10%;
        background-repeat: no-repeat;
    }
    //HOVER EFFECT GRID AREA'S
    #ho-f {
        position: absolute;
        top: 0;
        left: 0;
        // width: 100%;
        // height: 100%;
        opacity: 0;
        background: rgba(0, 0, 0, 0.4);
        transition: opacity 0.25s;
        &:hover {
            opacity: 1;
            backdrop-filter: blur(5px);
            transition: ease-in-out 350ms;
        }
    }
    #ho-f > * {
        transform: translateY(30px);
        transition: transform 0.5s;
    }
    #ho-f:hover > * {
        transform: translateY(0);
    }
}
