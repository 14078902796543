.header-container {
    position: relative;
    width: 100vw;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    z-index: 100;
}
.header-left-side {
    position: relative;
    list-style-type: none;
    padding: 0;
    a {
        color: whitesmoke;
        text-decoration: none;
    }
    a.commercial-btn.active {
        color: #75d5fd;
        transition: 350ms ease-in-out;
    }
    a.fiction-btn.active {
        color: #75d5fd;
        transition: 350ms ease-in-out;
    }
}
.bbk-logo {
    position: relative;
}
.header-right-side {
    position: relative;
    list-style-type: none;
    padding: 0;
    a {
        color: whitesmoke;
        text-decoration: none;
    }
    a.about-btn.active {
        color: #75d5fd;
        transition: 350ms ease-in-out;
    }
    a.contact-btn.active {
        color: #75d5fd;
        transition: 350ms ease-in-out;
    }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .desktop-container {
        display: none;
    }
    #mobile-container {
        position: fixed;
        // display: flex; ///Inline-block
        // align-items: center;
        width: 100vw;
        min-height: 11.5vh;
        background: linear-gradient(90deg, #060603, #060606);
        // border-bottom: 0.5px solid #f5a1a2ba;
        // margin-top: 15px;
        // top: 8vh;
    }
    #mobile-container:after {
        content: "";
        position: absolute;
        background: #060603;
        border-bottom: 0.5px solid #f5a1a2ba;
        bottom: 0;
        left: 6.5%;
        right: 6.5%;
        width: 87%;
    }
    .nav-buttons {
        position: absolute;
        display: flex;
        justify-content: space-evenly;
        width: 100%; ///was 100vw
        top: 4rem;
        margin: 0;
        padding: 0;
        // margin: 65px 0px 0px 0px;
        list-style-type: none;
        // top: 50%;
        a {
            color: whitesmoke;
            font-size: 13.5px;
            text-decoration: none;
            letter-spacing: 0.09em;
        }
        a.commercial-btn.active {
            color: #75d5fd;
            transition: 350ms ease-in-out;
        }
        a.fiction-btn.active {
            color: #75d5fd;
            transition: 350ms ease-in-out;
        }
        a.about-btn.active {
            color: #75d5fd;
            transition: 350ms ease-in-out;
        }
        a.contact-btn.active {
            color: #75d5fd;
            transition: 350ms ease-in-out;
        }
    }
    .nav-buttons > li:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
    }
    .bbk-logo-mobile {
        position: absolute;
        width: 22vw;
        // left: 50%;
        // top: 1.5rem;
        // transform: translate(-50%);
        // margin-top: 1.5rem;
        margin-bottom: 15px;
        img {
            width: 100%;
        }
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
    .desktop-container {
        position: fixed;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-top: 15px;
    }
    #mobile-container {
        display: none;
    }
    .bbk-logo-mobile {
        display: none;
    }
    .header-left-side {
        display: flex;
        justify-content: flex-end;
        width: 40vw;
        a {
            font-size: 13.5px;
            font-weight: 100;
            letter-spacing: 0.1em;
        }
    }
    #root > header > .desktop-container > ul.header-left-side > li:nth-child(1) {
        position: relative;
        right: 2rem;
    }
    .bbk-logo {
        display: flex;
        justify-content: center;
        width: 20vw;
        img {
            width: 50%;
        }
    }
    .header-right-side {
        display: flex;
        justify-content: flex-start;
        width: 40vw;
        a {
            font-size: 13.5px;
            font-weight: 100;
            letter-spacing: 0.1em;
        }
    }
    #root > header > .desktop-container > ul.header-right-side > li:nth-child(2) {
        position: relative;
        left: 2rem;
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    #mobile-container {
        position: fixed;
        display: flex;
        align-items: center;
        width: 100vw;
        margin-top: 30px;
        top: 8vh;
    }
    .desktop-container {
        display: none;
    }
    .nav-buttons {
        position: absolute;
        display: flex;
        justify-content: space-evenly;
        width: 100vw;
        margin: 0;
        padding: 0;
        list-style-type: none;
        top: 50%;
        a {
            color: whitesmoke;
            font-size: 21.5px;
            text-decoration: none;
            letter-spacing: 0.09em;
        }
        a.commercial-btn.active {
            color: #75d5fd;
            transition: 350ms ease-in-out;
        }
        a.fiction-btn.active {
            color: #75d5fd;
            transition: 350ms ease-in-out;
        }
        a.about-btn.active {
            color: #75d5fd;
            transition: 350ms ease-in-out;
        }
        a.contact-btn.active {
            color: #75d5fd;
            transition: 350ms ease-in-out;
        }
    }
    .bbk-logo-mobile {
        position: fixed;
        width: 20vw;
        top: 4vh;
        left: 50%;
        transform: translate(-50%);
        img {
            width: 100%;
        }
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .desktop-container {
        position: fixed;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-top: 15px;
    }
    #mobile-container {
        display: none;
    }
    .bbk-logo-mobile {
        display: none;
    }
    .header-left-side {
        display: flex;
        justify-content: flex-end;
        width: 40vw;
        a {
            color: whitesmoke;
            font-size: 19px;
            font-weight: 100;
            letter-spacing: 0.1em;
            text-decoration: none;
        }
    }
    #root > header > .desktop-container > ul.header-left-side > li:nth-child(1) {
        position: relative;
        right: 2rem;
    }
    .bbk-logo {
        display: flex;
        justify-content: center;
        width: 20vw;
        img {
            width: 55%;
        }
    }
    .header-right-side {
        display: flex;
        justify-content: flex-start;
        width: 40vw;
        transform: scale(1);
        a {
            color: whitesmoke;
            font-size: 19px;
            font-weight: 100;
            letter-spacing: 0.1em;
            text-decoration: none;
        }
    }
    #root > header > .desktop-container > ul.header-right-side > li:nth-child(2) {
        position: relative;
        left: 2rem;
    }
}
///Desktop
@media screen and (min-width: 1280px) {
    .desktop-container {
        position: fixed;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-top: 15px;
    }
    #mobile-container {
        display: none;
    }
    .bbk-logo-mobile {
        display: none;
    }
    .header-left-side {
        display: flex;
        justify-content: flex-end;
        width: 40vw;
        // transform: scale(1);
        a {
            color: whitesmoke;
            font-size: 19px;
            font-weight: 400;
            letter-spacing: 0.1em;
            text-decoration: none;
            &:hover {
                color: #f5a1a3;
                transition: 400ms ease-in-out;
            }
        }
    }
    #root > header > .desktop-container > ul.header-left-side > li:nth-child(1) {
        position: relative;
        right: 3.5rem;
    }
    .bbk-logo {
        display: flex;
        justify-content: center;
        width: 20vw;
        img {
            width: 45%;
        }
    }
    .header-right-side {
        display: flex;
        justify-content: flex-start;
        width: 40vw;
        // transform: scale(1);
        a {
            color: whitesmoke;
            font-size: 19px;
            font-weight: 400;
            letter-spacing: 0.1em;
            text-decoration: none;
            &:hover {
                color: #f5a1a3;
                transition: 400ms ease-in-out;
            }
        }
    }
    #root > header > .desktop-container > ul.header-right-side > li:nth-child(2) {
        position: relative;
        left: 3.5rem;
    }
}
