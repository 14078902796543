#commercial-container {
    position: relative;
    background-color: black;
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    #grid-wrapper {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(10, 90vh); ///eventueel 100vh
        // grid-auto-rows: 1fr;
        gap: 5px;
    }
    .one {
        grid-column: 1/2;
        grid-row: 1/2;
    }
    .two {
        grid-column: 1/2;
        grid-row: 2/3;
    }
    .three {
        grid-column: 1/2;
        grid-row: 3/4;
    }
    .four {
        grid-column: 1/2;
        grid-row: 4/5;
    }
    .five {
        grid-column: 1/2;
        grid-row: 5/6;
    }
    .six {
        grid-column: 1/2;
        grid-row: 6/7;
    }
    .seven {
        grid-column: 1/2;
        grid-row: 7/8;
    }
    .eight {
        grid-column: 1/2;
        grid-row: 8/9;
    }
    .nine {
        grid-column: 1/2;
        grid-row: 9/10;
    }
    .ten {
        grid-column: 1/2;
        grid-row: 10/11;
    }
    //CARD ONE
    #grid-wrapper div.one a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.one > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.one > a div {
        background-size: auto 150%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper div.two a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.two > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.two > a div {
        background-size: auto 110%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper div.three a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.three > a {
        text-decoration: none;
        // text-align: center;
    }
    #grid-wrapper > div.three > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper div.four a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.four > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.four > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper div.five a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.five > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.five > a div {
        background-size: auto 150%;
        background-position: 40%;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper div.six a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.six > a {
        text-decoration: none;
    }
    #grid-wrapper > div.six > a div {
        background-size: auto 100%;
        background-position: 35%;
        background-repeat: no-repeat;
    }
    //CARD SEVEN
    #grid-wrapper div.seven a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.seven > a {
        text-decoration: none;
    }
    #grid-wrapper > div.seven > a div {
        background-size: auto 115%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD EIGHT
    #grid-wrapper div.eight a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.eight > a {
        text-decoration: none;
    }
    #grid-wrapper > div.eight > a div {
        background-size: auto 115%;
        background-position: 40%;
        background-repeat: no-repeat;
    }
    //CARD NINE
    #grid-wrapper div.nine a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.nine > a {
        text-decoration: none;
    }
    #grid-wrapper > div.nine > a div {
        background-size: auto 115%;
        background-position: 75%;
        background-repeat: no-repeat;
    }
    //CARD TEN
    #grid-wrapper div.ten a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.ten > a {
        text-decoration: none;
    }
    #grid-wrapper > div.ten > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
    #grid-wrapper {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(8, 200px);
        gap: 5px;
    }
    //// 2 BIG
    .one {
        grid-column: 1/4;
        grid-row: 1/3;
    }
    .two {
        grid-column: 4/7;
        grid-row: 1/3;
    }
    //// 3 SMALL
    .three {
        grid-column: 1/3;
        grid-row: 3/5;
    }
    .four {
        grid-column: 3/5;
        grid-row: 3/5;
    }
    .five {
        grid-column: 5/7;
        grid-row: 3/5;
    }
    //// 2 BIG
    .six {
        grid-column: 1/4;
        grid-row: 5/7;
    }
    .seven {
        grid-column: 4/7;
        grid-row: 5/7;
    }
    ///// 3 SMALL
    .eight {
        grid-column: 1/3;
        grid-row: 7/9;
    }
    .nine {
        grid-column: 3/5;
        grid-row: 7/9;
    }
    .ten {
        grid-column: 5/7;
        grid-row: 7/9;
    }
    //CARD TEN
    #grid-wrapper div.ten a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.ten > a {
        text-decoration: none;
    }
    #grid-wrapper > div.ten > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD NINE
    #grid-wrapper div.nine a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.nine > a {
        text-decoration: none;
    }
    #grid-wrapper > div.nine > a div {
        background-size: auto 120%;
        background-position: 75%;
        background-repeat: no-repeat;
    }
    //CARD EIGHT
    #grid-wrapper div.eight a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.eight > a {
        text-decoration: none;
    }
    #grid-wrapper > div.eight > a div {
        background-size: auto 120%;
        background-position: 45%;
        background-repeat: no-repeat;
    }
    //CARD SEVEN
    #grid-wrapper div.seven a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.seven > a {
        text-decoration: none;
    }
    #grid-wrapper > div.seven > a div {
        background-size: auto 115%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper div.six a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.six > a {
        text-decoration: none;
    }
    #grid-wrapper > div.six > a div {
        background-size: auto 100%;
        background-position: 30%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper div.five a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.five > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.five > a div {
        background-size: auto 150%;
        background-position: 45%;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper div.four a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.four > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.four > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper div.three a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.three > a {
        text-decoration: none;
        // text-align: center;
    }
    #grid-wrapper > div.three > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper div.two a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.two > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.two > a div {
        background-size: auto 135%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD ONE
    #grid-wrapper div.one a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 25px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.one > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.one > a div {
        background-size: auto 150%;
        background-position: center;
        background-repeat: no-repeat;
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    #grid-wrapper {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(12, 200px);
        gap: 5px;
    }
    //// 2 BIG
    .one {
        grid-column: 1/4;
        grid-row: 1/4;
    }
    .two {
        grid-column: 4/7;
        grid-row: 1/4;
    }
    //// 3 SMALL
    .three {
        grid-column: 1/3;
        grid-row: 4/7;
    }
    .four {
        grid-column: 3/5;
        grid-row: 4/7;
    }
    .five {
        grid-column: 5/7;
        grid-row: 4/7;
    }
    //// 2 BIG
    .six {
        grid-column: 1/4;
        grid-row: 7/10;
    }
    .seven {
        grid-column: 4/7;
        grid-row: 7/10;
    }
    ///// 3 SMALL
    .eight {
        grid-column: 1/3;
        grid-row: 10/13;
    }
    .nine {
        grid-column: 3/5;
        grid-row: 10/13;
    }
    .ten {
        grid-column: 5/7;
        grid-row: 10/13;
    }
    //CARD TEN
    #grid-wrapper div.ten a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.ten > a {
        text-decoration: none;
    }
    #grid-wrapper > div.ten > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD NINE
    #grid-wrapper div.nine a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.nine > a {
        text-decoration: none;
    }
    #grid-wrapper > div.nine > a div {
        background-size: auto 115%;
        background-position: 75%;
        background-repeat: no-repeat;
    }
    //CARD EIGHT
    #grid-wrapper div.eight a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.eight > a {
        text-decoration: none;
    }
    #grid-wrapper > div.eight > a div {
        background-size: auto 120%;
        background-position: 40%;
        background-repeat: no-repeat;
    }
    //CARD SEVEN
    #grid-wrapper div.seven a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.seven > a {
        text-decoration: none;
    }
    #grid-wrapper > div.seven > a div {
        background-size: auto 115%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper div.six a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.six > a {
        text-decoration: none;
    }
    #grid-wrapper > div.six > a div {
        background-size: auto 100%;
        background-position: 25%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper div.five a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.five > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.five > a div {
        background-size: auto 150%;
        background-position: 40%;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper div.four a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.four > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.four > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper div.three a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.three > a {
        text-decoration: none;
        // text-align: center;
    }
    #grid-wrapper > div.three > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper div.two a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.two > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.two > a div {
        background-size: auto 135%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD ONE
    #grid-wrapper div.one a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.one > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.one > a div {
        background-size: auto 140%;
        background-position: center;
        background-repeat: no-repeat;
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    #grid-wrapper {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(12, 200px);
        gap: 5px;
    }
    //// 2 BIG
    .one {
        grid-column: 1/4;
        grid-row: 1/4;
    }
    .two {
        grid-column: 4/7;
        grid-row: 1/4;
    }
    //// 3 SMALL
    .three {
        grid-column: 1/3;
        grid-row: 4/7;
    }
    .four {
        grid-column: 3/5;
        grid-row: 4/7;
    }
    .five {
        grid-column: 5/7;
        grid-row: 4/7;
    }
    //// 2 BIG
    .six {
        grid-column: 1/4;
        grid-row: 7/10;
    }
    .seven {
        grid-column: 4/7;
        grid-row: 7/10;
    }
    ///// 3 SMALL
    .eight {
        grid-column: 1/3;
        grid-row: 10/13;
    }
    .nine {
        grid-column: 3/5;
        grid-row: 10/13;
    }
    .ten {
        grid-column: 5/7;
        grid-row: 10/13;
    }
    //CARD TEN
    #grid-wrapper div.ten a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.ten > a {
        text-decoration: none;
    }
    #grid-wrapper > div.ten > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD NINE
    #grid-wrapper div.nine a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.nine > a {
        text-decoration: none;
    }
    #grid-wrapper > div.nine > a div {
        background-size: auto 120%;
        background-position: 75%;
        background-repeat: no-repeat;
    }
    //CARD EIGHT
    #grid-wrapper div.eight a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.eight > a {
        text-decoration: none;
    }
    #grid-wrapper > div.eight > a div {
        background-size: auto 120%;
        background-position: 45%;
        background-repeat: no-repeat;
    }
    //CARD SEVEN
    #grid-wrapper div.seven a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.seven > a {
        text-decoration: none;
    }
    #grid-wrapper > div.seven > a div {
        background-size: auto 120%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper div.six a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.six > a {
        text-decoration: none;
    }
    #grid-wrapper > div.six > a div {
        background-size: auto 100%;
        background-position: 30%;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper div.five a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.five > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.five > a div {
        background-size: auto 150%;
        background-position: 45%;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper div.four a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.four > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.four > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper div.three a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.three > a {
        text-decoration: none;
        // text-align: center;
    }
    #grid-wrapper > div.three > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper div.two a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.two > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.two > a div {
        background-size: auto 135%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD ONE
    #grid-wrapper div.one a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.one > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.one > a div {
        background-size: auto 150%;
        background-position: center;
        background-repeat: no-repeat;
    }
}
///Desktop
@media screen and (min-width: 1280px) {
    #grid-wrapper {
        display: grid;
        // max-width: 1920px;
        margin: 0 auto;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(12, 200px);
        gap: 5px;
    }
    //// 2 BIG
    .one {
        grid-column: 1/4;
        grid-row: 1/4;
    }
    .two {
        grid-column: 4/7;
        grid-row: 1/4;
    }
    //// 3 SMALL
    .three {
        grid-column: 1/3;
        grid-row: 4/7;
    }
    .four {
        grid-column: 3/5;
        grid-row: 4/7;
    }
    .five {
        grid-column: 5/7;
        grid-row: 4/7;
    }
    //// 2 BIG
    .six {
        grid-column: 1/4;
        grid-row: 7/10;
    }
    .seven {
        grid-column: 4/7;
        grid-row: 7/10;
    }
    ///// 3 SMALL
    .eight {
        grid-column: 1/3;
        grid-row: 10/13;
    }
    .nine {
        grid-column: 3/5;
        grid-row: 10/13;
    }
    .ten {
        grid-column: 5/7;
        grid-row: 10/13;
    }
    //CARD TEN
    #grid-wrapper div.ten a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.ten > a {
        text-decoration: none;
    }
    #grid-wrapper > div.ten > a div {
        background-size: auto 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    //CARD NINE
    #grid-wrapper div.nine a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.nine > a {
        text-decoration: none;
    }
    #grid-wrapper > div.nine > a div {
        background-size: auto 120%;
        background-position: 75%;
        background-repeat: no-repeat;
    }
    //CARD EIGHT
    #grid-wrapper div.eight a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.eight > a {
        text-decoration: none;
    }
    #grid-wrapper > div.eight > a div {
        background-size: auto 120%;
        background-position: 45%;
        background-repeat: no-repeat;
    }
    //CARD SEVEN
    #grid-wrapper div.seven a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.seven > a {
        text-decoration: none;
    }
    #grid-wrapper > div.seven > a div {
        background-size: auto 120%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD SIX
    #grid-wrapper div.six a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.six > a {
        text-decoration: none;
    }
    #grid-wrapper > div.six > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD FIVE
    #grid-wrapper div.five a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.five > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.five > a div {
        background-size: auto 150%;
        background-position: 45%;
        background-repeat: no-repeat;
    }
    //CARD FOUR
    #grid-wrapper div.four a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.four > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.four > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD THREE
    #grid-wrapper div.three a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.three > a {
        text-decoration: none;
        // text-align: center;
    }
    #grid-wrapper > div.three > a div {
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD TWO
    #grid-wrapper div.two a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.two > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.two > a div {
        background-size: auto 140%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //CARD ONE
    #grid-wrapper div.one a div {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: whitesmoke;
        font-size: 30px;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
    }
    #grid-wrapper > div.one > a {
        text-decoration: none;
        text-align: center;
    }
    #grid-wrapper > div.one > a div {
        background-size: auto 145%;
        background-position: center;
        background-repeat: no-repeat;
    }
    //HOVER EFFECT GRID AREA'S
    .hover-overlay {
        position: absolute;
        top: 0;
        left: 0;
        // width: 100%;
        // height: 100%;
        opacity: 0;
        background: rgba(0, 0, 0, 0.4);
        transition: opacity 0.25s;
        &:hover {
            opacity: 1;
            backdrop-filter: blur(5px);
            transition: ease-in-out 350ms;
        }
    }
    .hover-overlay > * {
        transform: translateY(30px);
        transition: transform 0.5s;
    }
    .hover-overlay:hover > * {
        transform: translateY(0);
    }
}
