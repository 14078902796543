.topsection-container {
    position: relative;
    width: 100vw;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
}
///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .contact-container-desktop {
        display: none;
    }
    .contact-container-mobile {
        width: 100%;
        max-width: 30em;
        padding: 17.5vh 3.5em;
        min-height: 100vh;
        // background-position: center center;
        // background-size: cover;
        // -webkit-background-size: cover;
        // background-repeat: no-repeat;
        // background-attachment: fixed !important;
    }
    .contact-container-mobile .cards {
        margin: 35px auto;
        line-height: 1.5rem;
        width: 100%;
        max-width: 200px;
        // background: linear-gradient(transparent 9%, whitesmoke 32.5%, whitesmoke 90%, transparent 100%);
        // background: #040bc5 linear-gradient(to right, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 100%);
        // background: linear-gradient(40deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), #060606, #060606, #060606);

        // background-clip: text !important;
        // -webkit-background-clip: text !important;
        // -moz-background-clip: text !important;
        // -ms-background-clip: text !important;
        // background-attachment: fixed !important;
        // color: transparent !important;
        // -webkit-text-fill-color: transparent !important;
        // -moz-text-fill-color: transparent !important;
        // -ms-text-fill-color: transparent !important;
        h1 {
            letter-spacing: 0.03rem;
            font-size: 14px;
            margin-bottom: 2.5px;
            color: whitesmoke;
        }
        h3 {
            letter-spacing: 0.03rem;
            line-height: 1.4rem;
            font-size: 12.5px; /// WAS 13.5PX
            font-weight: 100;
            margin: 0;
            color: whitesmoke;
        }
        a {
            text-decoration: none;
        }
    }
    ///HQ BBKK
    .content-details-phone-five > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///EMILY
    .content-details-phone-two > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///QUINTIN
    .content-details-phone > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///LAUREN
    .content-details-phone-four > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///CREATIVES
    #root > section > div > div.cards.three > h3:nth-child(4) {
        margin-top: 8px;
    }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
    .contact-container-mobile {
        display: none;
    }
    .contact-container-desktop {
        display: flex;
        flex-wrap: wrap;
        min-height: 100vh;
        width: 100%;
        // max-width: 45em;
        padding: 20vh 13em;
    }
    .contact-container-desktop .cards {
        margin: 5px auto; ///OR 10PX AUTO
        line-height: 1.5rem;
        width: 100%;
        max-width: 200px;
        h1 {
            letter-spacing: 0.03rem;
            color: whitesmoke;
            font-size: 14px;
            margin-bottom: 2.5px;
        }
        h3 {
            letter-spacing: 0.03rem;
            line-height: 1.4rem;
            color: whitesmoke;
            font-size: 12.5px;
            font-weight: 100;
            margin: 0;
        }
        a {
            text-decoration: none;
        }
    }
    ///HQ BBKK
    #root > section > div > div.cards.five > a.content-details-phone-five > h3 {
        color: #f5a1a3;
    }
    ///EMILY
    #root > section > div > div.cards.two > a.content-details-phone-two > h3 {
        color: #f5a1a3;
    }
    ///QUINTIN
    #root > section > div > div.cards.one > a.content-details-phone > h3 {
        color: #f5a1a3;
    }
    ///LAUREN
    #root > section > div > div.cards.four > a.content-details-phone-four > h3 {
        color: #f5a1a3;
    }
    ///CREATIVES
    #root > section > div > div.cards.three > h3:nth-child(4) {
        margin-top: 8px;
    }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
    .contact-container-mobile {
        display: none;
    }
    .contact-container-desktop {
        display: flex;
        flex-wrap: wrap;
        min-height: 100vh;
        width: 100%;
        // max-width: 45em;
        padding: 22.5vh 6em 40vh 6em; /// OR padding: 27vh 8em;
    }
    .contact-container-desktop .cards {
        margin: 5px auto; ///OR 10PX AUTO
        line-height: 1.5rem;
        width: 100%;
        max-width: 250px;
        padding: 0px 5px 0px 5px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 21px;
            margin-bottom: 10px;
        }
        h3 {
            letter-spacing: 0.05rem;
            // line-height: 1.4rem;
            color: whitesmoke;
            font-size: 19px;
            font-weight: 100;
            margin: 0;
        }
        a {
            text-decoration: none;
        }
    }
    ///HQ BBKK
    .content-details-phone-five > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///EMILY
    .content-details-phone-two > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///QUINTIN
    .content-details-phone > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///LAUREN
    .content-details-phone-four > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///CREATIVES
    #root > section > div > div.cards.three > h3:nth-child(4) {
        margin-top: 8px;
    }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
    .contact-container-mobile {
        display: none;
    }
    .contact-container-desktop {
        display: flex;
        flex-wrap: wrap;
        min-height: 100vh;
        width: 100%;
        // max-width: 45em;
        padding: 15.5vh 15em 21.5vh 15em;
    }
    .contact-container-desktop .cards {
        margin: 5px auto; ///OR 10PX AUTO
        line-height: 1.5rem;
        width: 100%;
        max-width: 250px;
        padding: 0px 5px 0px 5px;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 21px;
            margin-bottom: 10px;
        }
        h3 {
            letter-spacing: 0.05rem;
            // line-height: 1.4rem;
            color: whitesmoke;
            font-size: 19px;
            font-weight: 100;
            margin: 0;
        }
        a {
            text-decoration: none;
        }
    }
    ///HQ BBKK
    .content-details-phone-five > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///EMILY
    .content-details-phone-two > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///QUINTIN
    .content-details-phone > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///LAUREN
    .content-details-phone-four > h3:nth-child(1) {
        color: #f5a1a3;
    }
    ///CREATIVES
    #root > section > div > div.cards.three > h3:nth-child(4) {
        margin-top: 8px;
    }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
    .contact-container-mobile {
        display: none;
    }
    .contact-container-desktop {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // width: 100%;
        left: 50%;
        top: 11rem;
        transform: translate(-50%);
    }
    .contact-container-desktop .cards {
        display: flex;
        flex-direction: column;
        width: 35%;
        margin: 15px 0px 15px 50px; /// margin: 15px 20px;
        line-height: 1.5rem;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 19px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.03rem;
            line-height: 1.4rem;
            color: whitesmoke;
            font-size: 15px;
            font-weight: 100;
            margin: 0;
        }
        a {
            text-decoration: none;
        }
    }
    ///HQ BBKK
    #root > section > div > div.cards.five > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.five > a.content-details-phone-five > h3 {
        color: #f5a1a3;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    ///EMILY
    #root > section > div > div.cards.two > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.two > a.content-details-phone-two > h3 {
        color: #f5a1a3;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    ///QUINTIN
    #root > section > div > div.cards.one > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.one > a.content-details-phone > h3 {
        color: #f5a1a3;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    ///LAUREN
    #root > section > div > div.cards.four > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.four > a.content-details-phone-four > h3 {
        color: #f5a1a3;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    ///CREATIVES
    #root > section > div > div.cards.three > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.three > h3:nth-child(4) {
        margin-top: 8px;
    }
    ///SOCIALS
    #root > section > div > div.cards.six > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
    .contact-container-mobile {
        display: none;
    }
    .contact-container-desktop {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // width: 100%;
        left: 50%;
        top: 12rem;
        transform: translate(-50%);
    }
    .contact-container-desktop .cards {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin: 15px 0px 25px 95px; /// margin: 15px 20px;
        line-height: 1.5rem;
        h1 {
            letter-spacing: 0.05rem;
            color: whitesmoke;
            font-size: 20px;
            margin-bottom: 5px;
        }
        h3 {
            letter-spacing: 0.03rem;
            line-height: 1.4rem;
            color: whitesmoke;
            font-size: 16px;
            font-weight: 100;
            margin: 0;
        }
        a {
            text-decoration: none;
        }
    }
    ///HQ BBKK
    #root > section > div > div.cards.five > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.five > a.content-details-phone-five > h3 {
        color: #f5a1a3;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    ///EMILY
    #root > section > div > div.cards.two > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.two > a.content-details-phone-two > h3 {
        color: #f5a1a3;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    ///QUINTIN
    #root > section > div > div.cards.one > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.one > a.content-details-phone > h3 {
        color: #f5a1a3;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    ///LAUREN
    #root > section > div > div.cards.four > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.four > a.content-details-phone-four > h3 {
        color: #f5a1a3;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    ///CREATIVES
    #root > section > div > div.cards.three > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
    #root > section > div > div.cards.three > h3:nth-child(4) {
        margin-top: 8px;
    }
    ///SOCIALS
    #root > section > div > div.cards.six > a > h3 {
        opacity: 0.75;
        &:hover {
            opacity: 1;
            transition: ease-in-out 350ms;
        }
    }
}
